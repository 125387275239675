import type { Translation } from '../i18n-types'

const de_aoa = {
  aoa: {
    activity: {
      approvedAoa: 'Genehmigte <></>.',
      closedAoa: 'Hat <></> geschlossen.',
      createdAoa: 'Erstellte <></>.',
      createdCheckin: 'Erstellte neuen Check-In für <></>.',
      createdItem: 'Hat {itemDescription} <></> erstellt.',
      deletedAoa: 'Löschte <></>.',
      deletedItem: 'Löschte {itemDescription} <></>.',
      updatedAoa: 'Bearbeite <></>.',
      updatedItem: '{itemDescription} <></> bearbeitet.',
      updatedAttributes: 'Geändert: <></>.',
      updatedAttributesEntries: {
        comment: 'Kommentar',
        description: 'Beschreibung',
        itemType: 'Typ',
        movedToAoaId: 'Verschoben in andere AOA',
        periodEnd: 'Enddatum',
        periodStart: 'Startdatum',
        responsibleUserId: 'Zuständige Person',
        results: 'Action Ergebnisse',
        section: 'Sektion',
        tags: 'Tags',
        text: 'Titel',
        iteration: 'Iteration',
        status: 'Status',
        title: 'Titel',
      },
      activityTooltips: {
        allTagsRemoved: 'Alle Tags entfernt',
      },
    },
    itemTypes: {
      action: 'Action',
      intent: 'Intent',
      outcome: 'Outcome',
      text: 'Text',
    },
    metrics: {
      completedResults: 'Ergebnisse abgeschlossen',
      totalResults: 'Ergebnisse insgesamt',
      cancellation: 'Abgebrochen',
      resultCompletion: 'Ergebnisabschlussrate',
      confidenceLevel: 'Konfidenz',
      status: 'Status',
      executionProgress: 'Fortschritt',
      noDataAvailable: 'Keine Daten verfügbar',
      vsLastIteration: 'vs letzte Iteration',
      completion: 'Fertigstellung',
      myCompletion: 'Meine Fertigstellung',
      myResultCompletion: 'Meine Ergebnisabschlussrate',
      myConfidence: 'Meine Konfidenz',
      myExecutionProgress: 'Mein Fortschritt',
      myCancellation: 'Meine Abbrüche',
      sunburstChart: {
        companyStrategy: 'Unternehmensstrategie',
        colorBy: 'Einfärben nach',
        displayChartBy: 'Diagramm anzeigen nach',
        byAoasActions: 'AOAs & Actions',
        byStrategy: 'Strategie',
      },
    },
    dashboard: {
      myActions: {
        cardHeader: 'Meine Actions',
        cardDescription: {
          due: 'Ihnen wurden {totalCount} Actions zugewiesen, und {dueCount} warten auf Ihren Check-in.',
          noDue:
            'Ihnen wurden {totalCount} Actions zugewiesen. Alle Actions wurden eingecheckt.',
          noActions: 'Es sind keine Actions für Sie zugewiesen.',
        },
        checkinBadge: '{checkedinCount} / {totalCount} eingecheckt',
        emptyState: {
          header: 'Keine zugewiesenen Actions',
          description:
            'Ihnen wurden keine Actions zugewiesen, die für dieses AOA eingecheckt werden müssen.',
        },
      },
      userActions: {
        cardHeader: 'Actions von {userName}',
        cardDescription: {
          due: '{userName} hat {totalCount} Actions, von denen {dueCount} fällig sind.',
          noDue:
            '{userName} hat {totalCount} Actions, die alle eingecheckt sind.',
          noActions: '{userName} hat keine Actions zugewiesen.',
        },
        checkinBadge: '{checkedinCount} / {totalCount} eingecheckt',
        emptyState: {
          header: 'Keine zugewiesenen Actions',
          description:
            '{userName} hat keine Actions zugewiesen, die für dieses AOA eingecheckt werden müssen.',
        },
      },
      activityFeed: {
        cardDescription: {
          active:
            'Es gab {recentCount} Aktivität{{en}} in den letzten {days} Tagen.',
          noRecent: 'Es gab keine Aktivitäten in den letzten {days} Tagen.',
        },
        userCardDescription: {
          active:
            '{userName} hat {recentCount} Aktivität{{en}} in den letzten {days} Tagen.',
          noRecent:
            '{userName} hat keine Aktivitäten in den letzten {days} Tagen.',
        },
        cardHeader: 'Neueste Aktivitäten',
        emptyState: {
          description:
            'Es gibt keine kürzlichen Aktionen oder Updates in dieser AOA.',
          header: 'Keine kürzlichen Aktivitäten',
          descriptionUserActivity:
            '{userName} hat bisher noch keine Änderungen vorgenommen.',
        },
      },
      notificableOutcomes: {
        cardHeader: 'Kritische Ergebnisse',
        cardDescription:
          'Ergebnisse, deren Konfidenzgrad weniger als 50% beträgt oder die derzeit blockiert sind, werden hier angezeigt.',
        emptyState: {
          header: 'Keine kritischen Ergebnisse',
          description:
            'Es gibt keine Ergebnisse, die Ihre Aufmerksamkeit erfordern.',
        },
        badge: '{itemsAtRiskCount} kritisch{{|es|e}} Ergebnis{{se}}',
      },
      confidenceSunburst: {
        cardHeader: 'Konfidenz Verteilung',
        cardDescription:
          'Die Verteilung der Konfidenzgrade der Intents und Actions in dieser AOA.',
      },
    },
    buttons: {
      collapseItemCount: '{0} Element{{e}} anzeigen',
      createNewAoa: 'Neue AOA erstellen',
      createRootAoa: 'Erstelle Company AOA',
      newAoa: 'Neue AOA',
    },
    yesNoOutcome: {
      completed: 'Erledigt',
      failed: 'Fehlgeschlagen',
    },
    checkin: {
      commentStep: 'Haben Sie Kommentare oder Notizen hinzuzufügen?',
      fulfillmentStates: {
        completed:
          'Das Element ist abgeschlossen und alle Ziele wurden erreicht.',
        failed:
          'Das Element war nicht erfolgreich bei der Erreichung ihrer Ziele.',
        completedTitle: 'Abgeschlossen',
        failedTitle: 'Fehlgeschlagen',
        unknown: 'Das Element ist noch nicht abgeschlossen.',
        unknownTitle: 'In Bearbeitung',
      },
      comperatorTexts: {
        labels: {
          greaterThan: 'Größer als',
          greaterThanOrEqual: 'Größer oder gleich',
          lessThan: 'Kleiner als',
          lessThanOrEqual: 'Kleiner oder gleich',
          equal: 'Gleich',
        },
        targetValueOnly: {
          prefix: {
            equal: 'Das Ergebnis ist gleich ',
            greaterThan: 'Das Ergebnis ist größer als ',
            greaterThanOrEqual: 'Das Ergebnis ist größer oder gleich ',
            lessThan: 'Das Ergebnis ist kleiner als ',
            lessThanOrEqual: 'Das Ergebnis ist kleiner oder gleich ',
          },
          suffix: {
            equal: '',
            greaterThan: '',
            greaterThanOrEqual: '',
            lessThan: '',
            lessThanOrEqual: '',
          },
        },
        startAndTargetValue: {
          prefix: {
            equal: 'Das Ergebnis beginnt bei ',
            greaterThan: 'Das Ergebnis beginnt bei ',
            greaterThanOrEqual: 'Das Ergebnis beginnt bei ',
            lessThan: 'Das Ergebnis beginnt bei ',
            lessThanOrEqual: 'Das Ergebnis beginnt bei ',
          },
          suffix: {
            equal: ' und wird voraussichtlich ',
            greaterThan: ' und wird voraussichtlich überschreiten ',
            greaterThanOrEqual:
              ' und wird voraussichtlich erreichen oder überschreiten ',
            lessThan: ' und wird voraussichtlich weniger als ',
            lessThanOrEqual: ' und wird voraussichtlich weniger oder gleich ',
          },
        },
        startValueOnly: {
          prefix: {
            equal: 'Das Ergebnis beginnt bei ',
            greaterThan: 'Das Ergebnis beginnt bei ',
            greaterThanOrEqual: 'Das Ergebnis beginnt bei ',
            lessThan: 'Das Ergebnis beginnt bei ',
            lessThanOrEqual: 'Das Ergebnis beginnt bei ',
          },
          suffix: {
            equal: '',
            greaterThan: ' und wird voraussichtlich steigen',
            greaterThanOrEqual: ' und wird voraussichtlich steigen',
            lessThan: ' und wird voraussichtlich sinken',
            lessThanOrEqual: ' und wird voraussichtlich sinken',
          },
        },
      },
      expectedResult: 'Erwartetes Ergebnis',
      confidenceSlider: {
        balanced: 'Ausgeglichen',
        certain: 'Sicher',
        notConfident: 'Nicht zuversichtlich',
        quiteConfident: 'Ziemlich zuversichtlich',
        somewhatUncertain: 'Etwas unsicher',
      },
      confidenceStep:
        'Wie zuversichtlich sind Sie, zeitgerecht abschließen zu können?',
      createFirstCheckin: 'Ersten Check-In erstellen',
      due: 'Fällig',
      emptyCheckinList: 'Für Sie sind keine Check-Ins zugeteilt.',
      headline: 'Status Check-In',
      latestCheckin: 'Letzter Check-In',
      latestVersion: 'Aktuelle Version',
      newCheckin: 'Neuer Check-In',
      noCheckinsHistorical: 'Keine Check-Ins',
      progressStatusStep: 'Was ist der aktuelle Status dieses Elements?',
      resultStep: 'Wie ist Ihr Fortschritt in der Bearbeitung?',
      percentualProgressStep:
        'Wie viel Prozent des Fortschritts haben Sie erreicht?',
      subheader: 'Erstellen Sie Check-Ins zu Outcomes & Results',
    },
    common: {
      accountable: 'Verantwortlich',
      accountableTeam: 'Verantwortliches Team',
      actions: 'Actions',
      showMetrics: 'Statistiken anzeigen',
      activeAoas: 'Aktive AOAs',
      draftAoas: 'AOAs im Entwurf',
      alreadyImported: 'Bereits importiert',
      aoaTitle: 'AOA Titel',
      approver: 'Genehmigt',
      archivedAoas: 'Archivierte AOAs',
      checkin: 'Status Check-In',
      confidence: 'Erfolgswahrscheinlichkeit',
      emptyIntents: 'Es wurden noch keine Intents hinzugefügt.',
      emptyLooseOutcomes: 'Es wurden noch keine Outcomes hinzugefügt.',
      emptySharedReality: 'Es wurde noch keine Shared Reality hinzugefügt.',
      higherIntents: 'Higher Intents',
      inherted: 'Aus übergeordneter AOA',
      intents: 'Intents',
      itemHistory: 'Verlauf',
      iteration: 'Iteration',
      noIteration: 'Keine Iteration',
      outcome: 'Outcome',
      outcomes: 'Outcomes',
      parentAoa: 'Übergeordnete AOA',
      progress: 'Fortschritt',
      responsible: 'Zuständig',
      result: 'Result',
      selectedIteration: 'Zugeteilte Iteration',
      strategicContext: 'Strategischer Kontext',
      text: 'Text',
      title: 'Titel',
      untitledAoa: 'Unbenannte AOA',
    },
    details: {
      pageHeader: 'AOA Details',
      pageSubheader: 'Betrachten und verwalten einer AOA',
    },
    deleteItem: {
      deleteModalTitle:
        'Sind Sie sicher, dass Sie dieses Element löschen wollen?',
    },
    filterPage: {
      cardHeader: 'Alle Intents, Outcomes, Actions & Results',
      lastCheckinRangeLabel: 'Letzter Check-In',
      noResultsDescription:
        'Ihre angewendeten Filter haben keine Ergebnisse geliefert.',
      pageHeader: 'AOA Dashboard',
      pageSubheader: 'Filtern und betrachten Sie AOAs und deren Elemente',
    },
    forms: {
      requiredField: 'Pflichtfeld',
      accountableRequired: 'Verantwortliche Person ist erforderlich',
      accountableTeamRequired: 'Verantwortliches Team ist erforderlich',
      resultRequired: 'Ergebnis ist erforderlich',
      add: {
        action: 'Action hinzufügen…',
        higherIntent: 'Higher Intent hinzufügen…',
        intent: 'Intent hinzufügen…',
        outcome: 'Outcome hinzufügen…',
        text: 'Item hinzufügen…',
      },
      booleanTypeLabel: 'Erfüllt oder nicht erfüllt',
      confidenceRequired: 'Konfidenz ist erforderlich',
      iterationRequired: 'Iteration ist erforderlich',
      numericTypeLabel: 'Messbarer Fortschritt',
      outcomeType: 'Art des Ergebnisses',
      periodEnd: 'Enddatum',
      periodEndRequired: 'Enddatum ist erforderlich',
      periodStart: 'Startdatum',
      periodStartRequired: 'Startdatum ist erforderlich',
      plannedQuantifier: 'Geplanter Ergebniswert',
      plannedStartingQuantifier: 'Geplanter Startwert',
      responsibleRequired: 'Zuständige Person ist erforderlich',
      selectResponsible: 'Zuständige Person auswählen…',
      textRequired: 'Text ist erforderlich',
      titleRequired: 'Titel ist erforderlich',
      removeResult: 'Ergebnis entfernen',
      addResult: 'Ergebnis hinzufügen',
      addKpi: 'Key Performance Indicator hinzufügen',
      comperator: 'Vergleichsoperator',
    },
    import: {
      hideAllAvailableItems: 'Alle {status} Elemente ausblenden',
      showAllAvailableItems: 'Alle {status} Elemente anzeigen',
      importPreviousAssignedHeadline:
        'Zugewiesene Elemente in vorheriger Iteration',
      noPreviousAssignedItemsAvailable:
        'Keine Elemente verfügbar die in der vorherigen Iteration zugewiesen wurden.',
      exitImport: 'Import verlassen',
      importButtonLabel: '{0} Elemente importieren',
      importButtonLabelEmpty: 'Keine Elemente zum Importieren',
      importCardHeadline: 'Aus vorheriger Iteration importieren',
      importCardSubheadline:
        'Einträge können entweder übernommen oder aus der vorherigen Iteration dupliziert werden. Übernommene Einträge sind mit der vorherigen Iteration verknüpft und zeigen Änderungen in der vorherigen Iteration an. Duplizierte Einträge sind unabhängig von der vorherigen Iteration.',
      importFromPreviousIteration: 'Aus vorheriger Iteration importieren',
      importFromPreviousIterationAction:
        'Action aus vorheriger Iteration importieren',
      importFromPreviousIterationIntent:
        'Intent aus vorheriger Iteration importieren',
      importFromPreviousIterationIntentAction:
        'Intent/Action aus vorheriger Iteration importieren',
      importFromPreviousIterationLooseOutcome:
        'Outcome aus vorheriger Iteration importieren',
      importFromPreviousIterationRootIntent:
        'Higher Intent aus vorheriger Iteration importieren',
      importItemsFromLabel: 'Importiere Elemente aus…',
      importModeSelector: 'Wie sollen die Einträge importiert werden?',
      importModeSelectorDescription:
        'Wählen Sie aus, ob die Einträge dupliziert oder übernommen werden sollen.',
      importModeSelectorDuplicate: 'Duplizieren',
      importModeSelectorDuplicateDescription:
        'Actions werden kopiert ohne Verweiß zur vorherigen Action. Vorherige Check-Ins werden nicht verknüpft.',
      importModeSelectorMove: 'Übernehmen',
      importModeSelectorMoveDescription:
        'Actions werden übernommen inkl. Verweiß und Check-Ins zur vorherigen Action.',
      importModeSelectorDuplicateSelected:
        'Einträge werden aus der vorherigen Iteration dupliziert',
      importModeSelectorMoveSelected:
        'Einträge werden aus der vorherigen Iteration übernommen',
      importNoItemsAvailable: 'Keine Einträge verfügbar',
      importNoItemsAvailableParentAoaDescription:
        'Es scheint, dass keine Einträge zum Importieren aus der übergeordneten AOA verfügbar sind.',
      importNoItemsAvailablePreviousIterationDescription:
        'Es scheint, dass keine Einträge zum Importieren aus der vorherigen Iteration verfügbar sind.',
      importSectionCardHeadline: '{section} importieren',
      importSuccessMessage: '{0} Element{{e}} erfolgreich importiert',
      movedToNextIterationDescription: 'Zur nächsten Iteration verschoben',
      movedToNextIterationShort: 'verschoben',
    },
    infos: {
      withdrawAoa: '{aoaName} schließen?',
      withdrawAoaDescription:
        'Diese AOA wird nicht mehr als aktiv betrachtet und andere Benutzer können keine Check-Ins mehr erstellen.',
      confirmApproveAoa: 'Dies wird die AOA aus dem Entwurfsstatus nehmen.',
      confirmApproveAoaDescription:
        'Sobald die AOA bestätigt wurde, können keine Elemente hinzugefügt oder gelöscht werden.',
      confirmDeleteAoa: 'Möchten Sie die AOA wirklich löschen?',
      confirmMultipaste: 'Möchten Sie wirklich mehrere Zeilen einfügen?',
      confirmTreeDelete:
        'Dies kann dazu führen, dass Elemente unterhalb gelöscht werden, was sich auf andere AOAs auswirken kann.',
      dragToReorder: 'Ziehen um die Reihenfolge zu ändern',
      enterOrDetails: 'Enter um zu speichern oder mehr Details eingeben.',
      enterOrPaste:
        'Enter um zu speichern oder mehrere Zeilen auf einmal einfügen.',
      outcomeTypeBoolean: 'Das Outcome kann entweder Ja oder Nein sein.',
    },
    iterationUnavailableReasons: {
      'aoa-cycle-contained-by-existing':
        'Die AOA hat bereits eine Iteration, die den gesamten Zeitraum abdeckt.',
      'aoa-cycle-exists':
        'Die AOA hat bereits eine Iteration für den Zeitraum.',
      'parent-aoa-not-approved': 'Die übergeordnete AOA ist nicht genehmigt.',
    },
    menu: {
      addAction: 'Action hinzufügen',
      addChildAoa: 'Untergeordnete AOA erstellen',
      addIteration: 'Iteration hinzufügen',
      checkin: 'Check-in erstellen',
      delete: 'Löschen',
      deleteAoa: 'AOA löschen',
      edit: 'Details bearbeiten',
      history: 'Verlauf anzeigen',
      viewParentAoa: 'Zur übergeordneten AOA wechseln',
      withdraw: 'AOA schließen',
      dangerZone: 'Gefahrenzone',
      editContent: 'Inhalte bearbeiten',
      newAoa: 'Neue AOAs erstellen',
      renameAoa: 'AOA umbenennen',
    },
    newAoa: {
      createNewIteration: 'Neue Iteration erstellen',
      emptyStates: {
        create: {
          company: 'Erstellen Sie eine Company-AOA, um zu beginnen.',
          department: 'Erstellen Sie Ihre erste Department-AOA.',
          team: 'Erstellen Sie Ihre erste Team-AOA.',
        },
        emptyContextInheritanceNotice:
          'Wenn keine Einträge hinzugefügt werden, werden die Einträge der übergeordneten AOA-Iteration übernommen.',
        noPermissionsToCreate: {
          company: 'Es wurde noch keine Company-AOA erstellt.',
          department: 'Es wurde noch keine Department-AOA erstellt.',
          team: 'Es wurde noch keine Team-AOA erstellt.',
        },
        setupOrganization: 'Organisation einrichten',
        unavailable: {
          company: 'Richten Sie zuerst Ihre Organisation ein',
          department: 'Erstellen Sie zuerst eine Company-AOA',
          team: 'Erstellen Sie zuerst eine Department-AOA',
        },
      },
      failedToCreateAoa: 'Fehler beim Erstellen der AOA',
      iterationSourceLabel:
        'Wählen Sie eine AOA aus, um eine neue Iteration zu erstellen',
      metadataPanelHeader: 'Titel, Iteration & Team',
      newAoaDescription:
        'Erstellen Sie eine neue leere AOA. Wählen Sie eine übergeordnete AOA und einen Zeitraum aus, um zu beginnen.',
      newAoaLabel: 'Neue AOA',
      newIterationDescription:
        'Fügen Sie einer bestehenden AOA eine neue Iteration hinzu. Dadurch wird eine neue Version der AOA für einen neuen Zeitraum erstellt.',
      newIterationLabel: 'Neue Iteration',
      pageSubheader:
        'Erstellen Sie eine neue AOA oder fügen Sie eine neue Iteration zu einer bestehenden hinzu',
      parentAoa: 'Übergeordnete AOA von {0}',
      parentSourceLabel: 'Wählen Sie die übergeordnete AOA aus',
      pickAoaLabel: 'AOA auswählen',
      pickParentAoaLabel: 'Wählen Sie eine übergeordnete AOA aus',
      previousIterationCycle: 'Vorherige Iteration',
      selectAoaPlaceholder: 'AOA auswählen…',
      selectAoaType: 'AOA Typ auswählen',
      selectParentAoaIteration: 'Übergeordnete AOA Iteration auswählen',
    },
    overview: {
      emptyAoaList: 'Für Ihre Organisation existieren noch keine AOAs.',
      pageHeader: 'AOA Übersicht',
      pageSubheader: 'Betrachten und verwalten Sie die AOAs der Organisation',
    },
    progress: {
      blocked: 'Blockiert',
      cancelled: 'Abgebrochen',
      delayed: 'Verzögert',
      done: 'Abschlossen',
      'on track': 'Im Zeitplan',
      open: 'offenen',
    },
    progressDescription: {
      blocked: 'Externe Faktoren hindern Sie daran, Fortschritte zu machen.',
      cancelled:
        'Das Ergebnis is nicht mehr relevant. Sie werden nicht mehr erinnert.',
      delayed: 'Sie machen Fortschritte, aber Sie sind im Verzug.',
      done: 'Alle geplanten Aktivitäten wurden fertig bearbeitet. Sie werden nicht mehr erinnert.',
      'on track': 'Sie machen wie geplant Fortschritte.',
    },
    sectionHelpers: {
      beliefs:
        'Was muss wahr sein, damit die Mission erfüllt werden kann? Was sind die wichtigsten Annahmen für den Erfolg?',
      boundaries:
        'Was darf nicht bei der Umsetzung passieren? Was ist nicht im Umfang?',
      challenges:
        'Identifizieren Sie potenzielle Schwierigkeiten und Herausforderungen, die bekannt sind.',
      higherIntents: 'Was sind die Higher Intents, die Ihre Strategie leiten?',
      input:
        'Was sind die wichtigsten Inputs, die für die Mission benötigt werden?',
      intentsActions:
        'Was sind die wichtigsten Absichten, die bis Ablauf des Zeitraums erfüllt werden müssen?',
      looseOutcomes: 'Was sind die wichtigsten Outcomes und Results',
      mission:
        'Wie wird die Vision zum Leben erweckt? Was ist der geplante Erfolgszustand in drei Jahren oder darüber hinaus?',
      statusQuo: 'Was ist der aktuelle Stand der Dinge?',
      vision: 'Was motiviert Sie morgens aufzustehen? Ihr Nordstern?',
    },
    sections: {
      beliefs: 'Beliefs',
      boundaries: 'Boundaries',
      challenges: 'Challenges',
      higherIntents: 'Higher Intents',
      input: 'Input',
      intentsActions: 'Intents & Actions',
      looseOutcomes: 'Targets',
      mission: 'Mission',
      statusQuo: 'Status Quo',
      vision: 'Vision',
    },
    tabSections: {
      intentsActions: 'Intents & Actions',
      outcomesResults: 'Targets',
      sharedReality: 'Shared Reality',
      strategy: 'Strategy',
      teamwork: 'Teamwork',
      visionMissionHigherIntents: 'Vision, Mission & Higher Intents',
      overview: 'Übersicht',
    },
    tooltips: {
      action:
        'Beschreibt die detaillierten Schritte, die unternommen werden müssen, um das zugehörige Intent zu erreichen. Jede Aktion soll konkrete Aufgaben beschreiben und mindestens ein qualitatives Ergebnis oder quantitatives Resultat liefern.',
      higherIntent:
        'Beschreibt die wichtigste und spezifische Leistung, die die übergeordnete Abteilung in den nächsten 12 Monaten ab jetzt erreichen möchte.',
      intent:
        'Beschreibt die wichtigste und spezifische Leistung, die Sie in den nächsten 12 Monaten ab jetzt erreichen möchten. Es definiert nur das, was Sie direkt beeinflussen und dazu beitragen können, um das gewidmete Higher Intent zu verwirklichen. Es ist die spezifische Anleitung, die nicht messbar sein muss. (Denken Sie in die richtige Richtung, nicht in Aufgaben oder Ergebnisse.)',
      movedFromPreviousIterationCount:
        'Bereits {0} mal aus vorheriger Iteration verschoben',
      outcome:
        "Jede Aktion erfordert mindestens ein qualitatives Ergebnis oder ein quantitatives Resultat. Für die Definition des Ergebnisses denken Sie an die 'Definition of Done'. Für Ergebnisse denken Sie an das Ambitionsniveau und die richtige Metrik. Ergebnisse und Ergebnisse können an eine Aktion oder eine AOA gebunden sein.",
      rootIntent:
        'Beschreibt, was Sie erreichen möchten, indem Sie die wichtigsten strategischen Säulen der Unternehmensmission für die nächsten 3 Jahre definieren. Es bietet Anleitung für eine fokussierte strategische Umsetzung.',
    },
    tree: {
      confidenceColHeader: 'Erfolgswahrscheinlichkeit',
      lastCheckinColHeader: 'Letzter Check-In',
      progressColHeader: 'Fortschritt/Status',
    },
  },
} satisfies Pick<Translation, 'aoa'>

export default de_aoa
